<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> User Management</a-breadcrumb-item>
        <a-breadcrumb-item> Privilege</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link to="/users/privilege/add"><PlusOutlined /> Add New Privilege</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>

      <a-row class="user-lists">
        <div class="body">
          <a-row><div class="title">Manage Privilege</div></a-row>
          <a-row class="filter">
            <a-col
              :xs="24"
              :sm="24"
              :md="{ span: 16, offset: 16 }"
              :lg="{ span: 16, offset: 16 }"
              :xl="{ span: 16, offset: 16 }"
            >
              <a-row :gutter="[16, 16]">
                <a-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
                  <a-input-search v-model:value="searchString" placeholder="Search" @search="onSearch"/>
                </a-col>
                <a-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <a-button type="primary"><FilterOutlined /> Filter</a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <div class="users">
            <a-table
              :columns="columns"
              :data-source="data"
              :pagination="{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '40', '50']
              }"
            >
              <span>
                <a-tag :color="status === 'Active' ? 'green' : 'red'">{{ status }}</a-tag>
              </span>
              <template v-slot:action>
                <a href="javascript:;">
                  <DeleteOutlined />
                </a>
              </template>
            </a-table>
          </div>
        </div>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { HomeOutlined, PlusOutlined, DeleteOutlined, FilterOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    DeleteOutlined,
    FilterOutlined
  },
  setup () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Email',
        dataIndex: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNo'
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Action',
        key: 'action',
        scopedSlots: { customRender: 'action' }
      }
    ]
    const data = [
      {
        key: '1',
        name: 'Nijesh W',
        email: 'nijesh.w.17@gmail.com',
        mobileNo: '9942542676',
        status: 'Active'
      },
      {
        key: '2',
        name: 'Rincy Raju',
        email: 'rincy@gmail.com',
        mobileNo: '9942542676',
        status: 'Active'
      },
      {
        key: '3',
        name: 'Akhil US',
        email: 'akhil@gmail.com',
        mobileNo: '9942542676',
        status: 'Inactive'
      }
    ]
    const searchString = ref<string>('')
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
    }
    return {
      columns,
      data,
      onSearch,
      searchString
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .user-lists {
    padding: 10px;
    .body {
      background: #ffffff;
      min-height: 200px;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      .title {
        color: #707070;
        float: left;
        font-size: 15px;
        text-transform: uppercase;
        font-family: "TT Norms Pro Medium";
      }
      .filter {
        .ant-select,
        .ant-btn {
          float: left;
        }
        .ant-btn {
          background: #38B6FF;
          border-color: #38B6FF;
          border-radius: 20px;
          font-family: "TT Norms Pro Medium";
        }
      }
      .users {
        margin-top: 20px;
      }
    }
  }
}
</style>
